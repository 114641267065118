import React from "react"
import classNames from "classnames";
import Markdown from "react-markdown"
import Image from "../image"
import ButtonLink from "../elements/button-link"
import { getButtonAppearance } from "@/utils/button"

const Hero = ({ data }) => {
  return (
    <main style={{background: 'linear-gradient(75deg, var(--tw-gradient-stops))'}} className={classNames("container flex flex-col-reverse md:flex-row items-center justify-between py-12", data.gradient)}>
      {/* Left column for content */}
      <div className="flex-1 sm:pr-8 px-6 lg:px-24 xl:px-24 xl2:px-28">
        {/* Hero section label */}
        <h1 className="text-4xl font-bold mt-2 sm:mt-0 mb-4 sm:mb-2">{data.label}</h1>
        {/* Big title */}
        <h1 className="text-4xl font-bold mt-2 sm:mt-0 mb-4 sm:mb-2">{data.title}</h1>
        {/* Description paragraph */}
        <p className="text-xl pt-6">{data.description}</p>
        {/* Small rich text */}
        <div className="sm:mt-3 rich-text-hero">
          <Markdown source={data.smallTextWithLink} />
        </div>
        {/* Buttons row */}
        <div className="flex flex-row flex-wrap mt-4 gap-4">
          {data.buttons.map(button => (
            <ButtonLink
              button={button}
              appearance={getButtonAppearance(button.type, "light")}
              key={button.id}
            />
          ))}
        </div>
      </div>
      {/* Right column for the image */}
      <Image
        media={data.picture}
        className="flex-shrink-0 object-contain w-full md:w-6/12 mb-6 md:mt-0 px-20 py-6 md:p-12 lg:p-24 xl:px-40 xl2:py-32 xl2:px-52"
      />
    </main>
  )
}

export default Hero