import React from "react"
import classNames from "classnames";
import Image from "../image";
import Video from "../elements/video";

const FeatureRowsListGroup = ({ data }) => {
  return (
    <div className="container flex flex-col">
      {data.features.map((feature, index) => (
        <div
          className={classNames(
            // Common classes
            "flex flex-col justify-start md:justify-between md:items-center sm:flex-col-reverse",
            {
              "lg:flex-row-reverse bg-gray-200": index % 2 === 0,
              "lg:flex-row bg-gray-100": index % 2 === 1,
            }
          )}
          key={feature.id}
        >
          {/* Text section */}
          <div className="w-full lg:w-6/12 text-lg p-16 md:p-32 lg:py-12 lg:px-24 xl:px-32 xl2:px-52">
            <h3 className="xl2:text-5xl xl:text-4xl lg:text-3xl sm:text-2xl sm:font-semibold">{feature.title}</h3>
            <ul className="list-disc text-xl font-medium p-4 xl2:pr-20">
            {feature.listItems.map((li) => (
              <li key={li.id} className="p-2 xl:p-3 xl2:p-4">
                {li.listItem}
              </li>
            ))}
            </ul>
          </div>
          {/* Media section */}
          <div className="w-full sm:9/12 lg:w-6/12 max-h-full">
            {/* Images */}
            {feature.media.mime.startsWith("image") && (
              <Image media={feature.media} className="w-full h-auto" />
            )}
            {/* Videos */}
            {feature.media.mime.startsWith("video") && (
              <Video
                media={feature.media}
                className="w-full h-auto"
                autoPlay
                controls={false}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureRowsListGroup;
