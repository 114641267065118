import React from "react"
import Markdown from "react-markdown"
import ButtonLink from "../elements/button-link"
import { getButtonAppearance } from "@/utils/button"

const Contact = ({ data }) => {
  return (
    <div className="container flex flex-col lg:flex-row items-center justify-between p-16" >
      {/* contact info */}
      <div className="flex flex-wrap xl:px-16">
        <div className="flex w-6/12 xl:p-8 prose prose-xl max-w-none">
          <Markdown source={data.adress1} />
        </div>
        <div className="flex w-6/12 xl:p-8 prose prose-xl max-w-none">
          <Markdown source={data.tel} />
        </div>
        <div className="flex w-6/12 xl:p-8 prose prose-xl max-w-none">
          <Markdown source={data.adress2} />
        </div>
        <div className="flex w-6/12 xl:p-8 prose prose-xl max-w-none">
          <Markdown source={data.email} />
        </div>
      </div>
      <div className="align-center p-16">
        {/* Big title */}
        <h1 className="text-2xl font-bold mt-2 sm:mt-0 mb-4 sm:mb-2">{data.Title}</h1>
        {/* Description paragraph */}
        <p className="text-xl py-6">{data.description}</p>
        <div className="flex">
          <ButtonLink
            button={data.button}
            appearance={getButtonAppearance(data.button.type, "light")}
            key={data.button.id}
          />
        </div>
      </div>
    </div>
  )
}

export default Contact