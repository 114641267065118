import React from "react"
import Markdown from "react-markdown"
import Image from "../image"

const About = ({ data }) => {
  return (
    <div className="container flex flex-col items-center justify-between p-16" >
      {/* about text */}
      <div className="w-full prose prose-xl max-w-none lg:px-40 pb-16">
        <Markdown source={data.about} />
      </div>
      <div className="align-center">
        {/* Big title */}
        <h1 className="text-4xl font-bold mt-2 sm:mt-0 mb-4 sm:mb-2">{data.title}</h1>
        {/* Description paragraph */}
        <p className="text-2xl py-6">{data.description}</p>
      </div>
      {/* four v's */}
      <div className="flex flex-wrap lg:px-16">
        <div className="flex lg:w-6/12 w-full lg:p-8 ">
          <Image
            media={data.bullet}
            className="w-10 h-10 m-8 mt-4"
          />
          <div className="prose prose-xl max-w-none">
            <Markdown source={data.v1} />
          </div>
        </div>
        <div className="flex lg:w-6/12 w-full lg:p-8 ">
          <Image
            media={data.bullet}
            className="w-10 h-10 m-8 mt-4"
          />
          <div className="prose prose-xl max-w-none">
            <Markdown source={data.v2} />
          </div>
        </div>
        <div className="flex lg:w-6/12 w-full lg:p-8 ">
          <Image
            media={data.bullet}
            className="w-10 h-10 m-8 mt-4"
          />
          <div className="prose prose-xl max-w-none">
            <Markdown source={data.v3} />
          </div>
        </div>
        <div className="flex lg:w-6/12 w-full lg:p-8 ">
          <Image
            media={data.bullet}
            className="w-10 h-10 m-8 mt-4"
          />
          <div className="prose prose-xl max-w-none">
            <Markdown source={data.v4} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About