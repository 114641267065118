import React from "react"
import classNames from "classnames";
import Image from "../image"
import ButtonLink from "../elements/button-link"
import { getButtonAppearance } from "@/utils/button"

const Products = ({ data }) => {
  return (
    <div className="container p-4 md:p-16">
      <div className="flex flex-col lg:flex-row gap-12 lg:justify-center">
        {data.products.map((product) => (
          <div
            className={classNames(
              // Common classes
              "rounded-md border-2 py-4 flex-1 flex flex-col md:w-lg text-gray-900 border-product"
            )}
            key={product.id}
          >
            <div>
              <Image
                  placeholder="none"
                  media={product.icon}
                  className="h-12 w-auto px-8"
                />
            </div>
            <div className={classNames("h-64 my-4", product.overlay)}>
              <Image
                  placeholder="none"
                  media={product.image}
                  className="h-60 w-auto mx-auto opacity-30"
                />
              <h2 className="relative xl2:-mt-32 lg:-mt-36 md:-mt-28 -mt-32 xl:text-4xl lg:text-3xl md:text-4xl text-3xl text-white p-8">{product.title}</h2>
            </div>
            <ul className="px-16 py-8 flex flex-col list-outside gap-6">
              {product.list.map((li) => (
                <li
                  className="list-disc text-lg"
                  key={li.id}
                >
                  <span>{li.listItem}</span>
                </li>
              ))}
            </ul>
            <span className="mt-auto px-12 py-4">
              <ButtonLink
                button={product.button}
                appearance={getButtonAppearance(product.button.type, "light")}
                key={product.button.id}
              />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
